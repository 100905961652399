import styled from "styled-components"
import { GoldButton } from "../../../SearchComponent/style"

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  &:nth-of-type(4) {
    background: #111111;
  }
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const LeftSide = styled.div`
  height: auto;
  order: ${props => (props.imageFirst ? "2" : "1")};
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: ${props =>
    props.imageFirst ? "20px 0 20px 30px" : "20px 30px 20px 0"};

  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.1rem;
    letter-spacing: 0;
    color: #fdfdfd;
  }

  @media (max-width: 799px) {
    width: 100%;
    order: ${props => (props.imageFirst ? "2" : "2")};
    padding: 20px 0;
  }
`

export const RightSide = styled.div`
  height: 450px;
  order: ${props => (props.imageFirst ? "1" : "2")};
  width: calc(50% - 10px);
  display: flex;
  justify-content: ${props => (props.imageFirst ? "flex-start" : "flex-end")};
  background-image: url(${props => props.image});
  z-index: 2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1600px) {
    height: 370px;
  }

  @media (max-width: 1400px) {
    height: 325px;
  }

  @media (max-width: 1250px) {
    height: 280px;
  }

  @media (max-width: 1149px) {
    background-size: cover;
    height: 350px;
    background-position: center;
  }

  @media (max-width: 900px) {
    width: 50%;
    height: 300px;
  }

  @media (max-width: 799px) {
    width: 100%;
    order: ${props => (props.imageFirst ? "1" : "1")};
    background-size: contain;
    background-position: center;
  }
`

export const GoldenButton = styled(GoldButton)`
  width: 100%;

  @media (max-width: 799px) {
    width: 100%;
  }
`

export const ButtonMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  width: 83.3%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 50px 0;

  a {
    width: calc(50% - 10px);

    @media (max-width: 799px) {
      width: 100%;
    }
  }

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const ArrowBox = styled.div`
  display: none;
  width: 100%;
  padding: 62px 0;

  img {
    width: 12px;
    height: 6px;
  }

  @media (max-width: 799px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
